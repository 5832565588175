html {
  color: #001e1b;
  font-size: 1em;
  line-height: 1.4;
}

body {
  max-height: 100vh;
  max-width: 100vw;
  margin: 0;
  font-family: OpenSans, sans-serif;
  overflow: hidden;
}

h1 {
  margin-top: 0;
  padding: 0;
  font-size: 1.7rem;
  line-height: 2.1rem;
}

h2 {
  margin-top: 0;
  padding: 0;
  font-size: 1.5rem;
}

a {
  color: #009e8b;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@font-face {
  font-family: Righteous;
  font-style: normal;
  font-size: 48px;
  src: url("Righteous-Regular.514cf35b.ttf") format("truetype");
}

@font-face {
  font-family: Matiz;
  font-style: normal;
  font-size: 48px;
  src: url("Matiz.f5642ed2.ttf") format("truetype");
}

@font-face {
  font-family: Freedom45;
  font-style: normal;
  font-size: 48px;
  src: url("Freedom45.6450d132.ttf") format("truetype");
}

@font-face {
  font-family: Capture It;
  font-style: normal;
  font-size: 48px;
  src: url("Capture it.f3638ec4.ttf") format("truetype");
}

@font-face {
  font-family: Tenada;
  font-style: normal;
  font-size: 48px;
  src: url("Tenada.8d8a6d54.ttf") format("truetype");
}

@font-face {
  font-family: Redemption;
  font-style: normal;
  font-size: 48px;
  src: url("Redemption.f525d08e.ttf") format("truetype");
}

.container {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chevron {
  width: 2.1rem;
  height: .48rem;
  opacity: 0;
  animation: 3s ease-out infinite move-chevron;
  position: absolute;
  transform: scale(.3);
}

.chevron:first-child {
  animation: 3s ease-out 1s infinite move-chevron;
}

.chevron:nth-child(2) {
  animation: 3s ease-out 2s infinite move-chevron;
}

.chevron:before, .chevron:after {
  content: "";
  height: 100%;
  width: 50%;
  background: #2c3e50;
  position: absolute;
  top: 0;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  width: 50%;
  right: 0;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }

  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }

  100% {
    opacity: 0;
    transform: translateY(4.8rem)scale(.5);
  }
}

body {
  overflow: hidden;
}

.title-container {
  width: 100vw;
  height: 80px;
  background-color: #001e1b;
  margin: 0;
  padding: 0;
}

.title {
  color: #fff;
  text-transform: uppercase;
  background-color: #001e1b;
  margin: auto;
  padding: 18px;
  font-family: sans-serif;
  font-size: 1.8rem;
}

.title-bold {
  font-weight: bold;
}

.title-logo {
  margin-right: 20px;
  display: none;
}

.title-menu {
  float: right;
  text-transform: none;
  text-align: center;
  min-width: 30px;
  min-height: 30px;
  display: inline-block;
}

.title-menu-item {
  margin: 0 10px 0 40px;
  display: none;
}

.title-highlighted {
  color: #001e1b;
  background-color: #009e8b;
  border-radius: 4px;
  margin-left: 2px;
  padding: 5px;
  font-weight: bolder;
}

.title-banner {
  display: none;
}

.splash {
  min-height: 350px;
  background-image: url("splash.a527f77c.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.menu {
  z-index: 999;
  background: #fff;
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
  overflow: hidden;
}

.menu a {
  color: #001e1b;
}

.menu a:hover {
  font-weight: 600;
  text-decoration: none;
}

.menu-item {
  padding: 8px;
}

.menu-icon {
  padding: 0 16px 0 0;
}

.menu-closed {
  max-width: 0;
  padding: 0;
  transition: width .5s cubic-bezier(0, .5, 0, 1), padding .5s cubic-bezier(0, .5, 0, 1);
}

.menu-open {
  min-width: 128px;
  padding: 20px;
  transition: width .5s cubic-bezier(0, .5, 0, 1), padding .5s cubic-bezier(0, .5, 0, 1);
}

.content {
  width: 100vw;
  min-height: calc(100vh - 155px);
  max-height: calc(100vh - 155px);
  margin: auto;
  overflow-y: scroll;
}

.footer {
  color: #fff;
  text-align: center;
  background-color: #001e1b;
  margin-top: 30px;
  padding: 14px 0;
  font-size: .85rem;
  display: grid;
}

.window {
  height: 217px;
}

.company-image {
  width: 100%;
  height: 358px;
  max-height: 30vh;
  background-image: url("logo-wordless-small.89d617be.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
}

.scroll-down {
  width: 100%;
  max-height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: calc(100vh - 120px);
}

.left-just {
  text-align: left;
  width: 95%;
  margin: auto;
}

.hidden {
  display: none;
}

.section1 {
  max-width: 800px;
  color: #001e1b;
  text-align: center;
  background-color: #fff;
  margin: auto;
  padding: 20px 12px;
}

.section2 {
  max-width: 800px;
  color: #001e1b;
  text-align: center;
  margin: auto;
  padding: 20px 12px;
}

.section-continuous {
  border-bottom: 0;
}

.services-list {
  text-align: left;
  max-width: 80vw;
  width: 85%;
  grid-template-columns: 40px auto;
  margin: 16px auto 0;
  padding-left: 45px;
  font-size: 1.125rem;
  display: grid;
}

.service-item {
  margin: 4px 0;
  font-weight: 100;
}

.button-container {
  width: 70%;
  margin: auto;
  display: grid;
}

.button, .button a {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background-color: #07485b;
  border: 1px solid #001e1b;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1.1rem;
  display: inline-flex;
}

.contact-container {
  margin-top: 20px;
  display: grid;
}

.input-label {
  width: 90%;
  text-align: left;
  margin: auto;
  padding-left: 18px;
  display: grid;
}

.fasp {
  padding-top: 6px;
  padding-right: 8px;
}

.text {
  margin-bottom: 5px;
  font-size: 1.125rem;
  font-weight: lighter;
}

.about-text {
  width: 95%;
  text-align: justify;
  margin: auto;
  padding: 0;
  line-height: 1.5;
}

.hidden {
  display: none;
}

.margined {
  margin: auto;
}

.spin {
  animation-name: animation-spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.contact-line {
  margin-bottom: 8px;
  position: relative;
  top: -1px;
}

@keyframes animation-spin {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 850px) {
  h1 {
    text-align: center;
    line-height: 1.5;
  }

  h2 {
    text-align: center;
  }

  .content {
    max-height: calc(100vh - 134px);
    min-height: calc(100vh - 134px);
  }

  .menu {
    display: none;
  }

  .title {
    width: 100%;
    padding: 0;
  }

  .title-banner {
    height: 200px;
    background-color: #009e8b;
    background-image: url("logo-top-banner.4f3c7feb.svg");
    background-position: 50% 35%;
    background-repeat: no-repeat;
    background-size: 400px;
    display: grid;
  }

  .title-under-banner {
    text-align: center;
    background-color: #009e8b;
    padding-top: 10px;
  }

  .title-under-banner a {
    color: #004940;
  }

  .title-menu {
    display: none;
  }

  .title-menu-item {
    display: inline-block;
  }

  .title-container {
    min-height: 55px;
    max-height: 55px;
    overflow: hidden;
  }

  .about-text {
    width: 75%;
    padding: 0 20px;
    line-height: 1.7;
  }

  .services-list {
    width: 75%;
  }

  .section1 {
    text-align: justify;
  }

  .left-just {
    width: 75%;
  }

  .section2 {
    text-align: justify;
  }

  .button {
    min-width: 15rem;
    max-width: 30rem;
    margin: auto;
    display: block;
  }

  .button-container {
    width: 70%;
    margin: auto;
    display: grid;
  }

  .contact-container {
    min-height: unset;
    box-shadow: unset;
  }

  .fasp {
    float: left;
  }
}

@media screen and (min-width: 1235px) {
  .title-container {
    min-height: 154px;
    max-height: 154px;
    overflow: hidden;
  }

  .title-logo {
    display: unset;
  }

  .content {
    max-height: calc(100vh - 233px);
    min-height: calc(100vh - 233px);
  }
}

/*# sourceMappingURL=index.a0da6c5b.css.map */
