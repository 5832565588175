/* color palette */
/* palette mapping */
html {
  color: #001e1b;
  font-size: 1em;
  line-height: 1.4;
}

body {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  margin: 0;
  font-family: OpenSans, sans-serif;
}

h1 {
  padding: 0;
  margin-top: 0;
  font-size: 1.7rem;
  line-height: 2.1rem;
}

h2 {
  padding: 0;
  margin-top: 0;
  font-size: 1.5rem;
}

a {
  text-decoration: none;
  color: #009e8b;
}

a:hover {
  text-decoration: underline;
}

@font-face {
  font-family: "Righteous";
  font-style: normal;
  font-size: 48px;
  src: url("../assets/Righteous-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Matiz";
  font-style: normal;
  font-size: 48px;
  src: url("../assets/matiz/Matiz.ttf") format("truetype");
}
@font-face {
  font-family: "Freedom45";
  font-style: normal;
  font-size: 48px;
  src: url("../assets/freedom45/Freedom45.ttf") format("truetype");
}
@font-face {
  font-family: "Capture It";
  font-style: normal;
  font-size: 48px;
  src: url("../assets/capture_it/Capture it.ttf") format("truetype");
}
@font-face {
  font-family: "Tenada";
  font-style: normal;
  font-size: 48px;
  src: url("../assets/tenada/Tenada.ttf") format("truetype");
}
@font-face {
  font-family: "Redemption";
  font-style: normal;
  font-size: 48px;
  src: url("../assets/redemption/Redemption.ttf") format("truetype");
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.chevron {
  position: absolute;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #2c3e50;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}
body {
  overflow: hidden;
}

.title-container {
  width: 100vw;
  height: 80px;
  background-color: #001e1b;
  padding: 0;
  margin: 0;
}

.title {
  margin: auto;
  background-color: #001e1b;
  color: white;
  font-family: sans-serif;
  font-size: 1.8rem;
  padding: 18px;
  text-transform: uppercase;
}

.title-bold {
  font-weight: bold;
}

.title-logo {
  display: none;
  margin-right: 20px;
}

.title-menu {
  float: right;
  text-transform: none;
  text-align: center;
  min-width: 30px;
  display: inline-block;
  min-height: 30px;
}

.title-menu-item {
  margin: 0 10px 0 40px;
  display: none;
}

.title-highlighted {
  background-color: #009e8b;
  padding: 5px;
  color: #001e1b;
  font-weight: bolder;
  border-radius: 4px;
  margin-left: 2px;
}

.title-banner {
  display: none;
}

.splash {
  background-image: url("../assets/splash.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 350px;
}

.menu {
  position: absolute;
  top: 60px;
  right: 0;
  background: white;
  display: block;
  overflow: hidden;
  z-index: 999;
}

.menu a {
  color: #001e1b;
}

.menu a:hover {
  font-weight: 600;
  text-decoration: none;
}

.menu-item {
  padding: 8px;
}

.menu-icon {
  padding: 0 16px 0 0;
}

.menu-closed {
  transition: width 500ms cubic-bezier(0, 0.5, 0, 1), padding 500ms cubic-bezier(0, 0.5, 0, 1);
  max-width: 0;
  padding: 0;
}

.menu-open {
  transition: width 500ms cubic-bezier(0, 0.5, 0, 1), padding 500ms cubic-bezier(0, 0.5, 0, 1);
  min-width: 128px;
  padding: 20px;
}

.content {
  width: 100vw;
  margin: auto;
  min-height: calc(100vh - 155px);
  max-height: calc(100vh - 155px);
  overflow-y: scroll;
}

.footer {
  font-size: 0.85rem;
  background-color: #001e1b;
  color: white;
  padding: 14px 0;
  margin-top: 30px;
  text-align: center;
  display: grid;
}

.window {
  height: 217px;
}

.company-image {
  width: 100%;
  height: 358px;
  max-height: 30vh;
  margin-bottom: 20px;
  background-image: url("../assets/logo-wordless-small.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll-down {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 80px;
  position: absolute;
  top: calc(100vh - 120px);
}

.left-just {
  text-align: left;
  width: 95%;
  margin: auto;
}

.hidden {
  display: none;
}

.section1 {
  max-width: 800px;
  margin: auto;
  background-color: white;
  color: #001e1b;
  text-align: center;
  padding: 20px 12px;
}

.section2 {
  max-width: 800px;
  margin: auto;
  color: #001e1b;
  text-align: center;
  padding: 20px 12px;
}

.section-continuous {
  border-bottom: 0;
}

.services-list {
  display: grid;
  grid-template-columns: 40px auto;
  font-size: 1.125rem;
  text-align: left;
  margin: 16px auto 0 auto;
  max-width: 80vw;
  width: 85%;
  padding-left: 45px;
}

.service-item {
  margin: 4px 0;
  font-weight: 100;
}

.button-container {
  display: grid;
  width: 70%;
  margin: auto;
}

.button {
  background-color: #07485B;
  color: white;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-align: center;
  display: inline-flex;
  padding: 10px 20px;
  border: 1px solid #001e1b;
  border-radius: 4px;
}

.button a {
  background-color: #07485B;
  color: white;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-align: center;
  display: inline-flex;
  padding: 10px 20px;
  border: 1px solid #001e1b;
  border-radius: 4px;
}

.contact-container {
  margin-top: 20px;
  display: grid;
}

.input-label {
  width: 90%;
  margin: auto;
  text-align: left;
  display: grid;
  padding-left: 18px;
}

.fasp {
  padding-right: 8px;
  padding-top: 6px;
}

.text {
  font-size: 1.125rem;
  font-weight: lighter;
  margin-bottom: 5px;
}

.about-text {
  width: 95%;
  margin: auto;
  padding: 0;
  text-align: justify;
  line-height: 1.5;
}

.hidden {
  display: none;
}

.margined {
  margin: auto;
}

.spin {
  animation-timing-function: linear;
  animation-name: animation-spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.contact-line {
  margin-bottom: 8px;
  position: relative;
  top: -1px;
}

@keyframes animation-spin {
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 850px) {
  h1 {
    text-align: center;
    line-height: 1.5;
  }
  h2 {
    text-align: center;
  }
  .content {
    max-height: calc(100vh - 134px);
    min-height: calc(100vh - 134px);
  }
  .menu {
    display: none;
  }
  .title {
    width: 100%;
    padding: 0;
  }
  .title-banner {
    display: grid;
    height: 200px;
    background-image: url("../assets/logo-top-banner.svg");
    background-position: 50% 35%;
    background-repeat: no-repeat;
    background-size: 400px;
    background-color: #009e8b;
  }
  .title-under-banner {
    padding-top: 10px;
    background-color: #009e8b;
    text-align: center;
  }
  .title-under-banner a {
    color: #004940;
  }
  .title-menu {
    display: none;
  }
  .title-menu-item {
    display: inline-block;
  }
  .title-container {
    min-height: 55px;
    max-height: 55px;
    overflow: hidden;
  }
  .about-text {
    width: 75%;
    padding: 0 20px;
    line-height: 1.7;
  }
  .services-list {
    width: 75%;
  }
  .section1 {
    text-align: justify;
  }
  .left-just {
    width: 75%;
  }
  .section2 {
    text-align: justify;
  }
  .button {
    min-width: 15rem;
    max-width: 30rem;
    margin: auto;
    display: block;
  }
  .button-container {
    display: grid;
    width: 70%;
    margin: auto;
  }
  .contact-container {
    min-height: unset;
    box-shadow: unset;
  }
  .fasp {
    float: left;
  }
}
@media screen and (min-width: 1235px) {
  .title-container {
    min-height: 154px;
    max-height: 154px;
    overflow: hidden;
  }
  .title-logo {
    display: unset;
  }
  .content {
    max-height: calc(100vh - 233px);
    min-height: calc(100vh - 233px);
  }
}

