/* color palette */
$black: black
$dark-gray: #222
$medium-gray: #666
$off-white: #f4f4f4
$white: white
$accent: #009e8b
$high-accent: #07485B
$accent-complement: #004940
$palette1: #007366
$palette2: #001e1b
$palette3: #00f3d6
$palette4: #00c8b0
$palette5: #009e8b

/* palette mapping */
$bg0: $palette2
$text0: $white
$bg1: $white
$text1: $palette2
$bg2: $off-white
$text2: $palette2
$text-link: $accent
$text-link2: $accent-complement
$text-label: $medium-gray


html
  color: $bg0
  font-size: 1em
  line-height: 1.4

body
  max-height: 100vh
  max-width: 100vw
  overflow: hidden
  margin: 0
  font-family: OpenSans, sans-serif

h1
  padding: 0
  margin-top: 0
  font-size: 1.7rem
  line-height: 2.1rem

h2
  padding: 0
  margin-top: 0
  font-size: 1.5rem

a
  text-decoration: none
  color: $text-link

a:hover
  text-decoration: underline

@font-face
  font-family: 'Righteous'
  font-style: normal
  font-size: 48px
  src: url('../assets/Righteous-Regular.ttf') format('truetype')

@font-face
  font-family: 'Matiz'
  font-style: normal
  font-size: 48px
  src: url('../assets/matiz/Matiz.ttf') format('truetype')

@font-face
  font-family: 'Freedom45'
  font-style: normal
  font-size: 48px
  src: url('../assets/freedom45/Freedom45.ttf') format('truetype')

@font-face
  font-family: 'Capture It'
  font-style: normal
  font-size: 48px
  src: url('../assets/capture_it/Capture it.ttf') format('truetype')

@font-face
  font-family: 'Tenada'
  font-style: normal
  font-size: 48px
  src: url('../assets/tenada/Tenada.ttf') format('truetype')

@font-face
  font-family: 'Redemption'
  font-style: normal
  font-size: 48px
  src: url('../assets/redemption/Redemption.ttf') format('truetype')
